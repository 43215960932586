import { GET_SUPPORT_INFORMATION, SET_SUPPORT_INFORMATION } from '../constants';

const initialState = { information: [] };

export function supportReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUPPORT_INFORMATION:
      return state;
    case SET_SUPPORT_INFORMATION:
      return { ...state, information: action.payload.information };
    default:
      return state;
  }
}
