import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@ingka/button';
import { getSupportInformation } from '../../../store/actions/supportActions';
import './Simulations.scss';

const Simulations = () => {
  const dispatch = useDispatch();
  const { information } = useSelector(state => state.support);
  const { authenticated } = useSelector(state => state.user);
  useEffect(() => {
    if (authenticated) {
      dispatch(getSupportInformation());
    }
  }, [dispatch, authenticated]);

  const handleClick = (event, target) => {
    event.preventDefault();
    window.open(target);
  };

  const informationCards = () => {
    return information.map(info => (
      <article key={info.title}>
        <h5>{info.title}</h5>
        <p>{info.text}</p>
        <Button
          className="contact-button"
          type="primary"
          text="More information"
          onClick={event => handleClick(event, info.target)}
        />
      </article>
    ));
  };

  return (
    <main className="ikea-container">
      <section>
        <article className="simulation-container">
          <h2>Simulations</h2>
          <Link className="link-button" to="/app/order-orchestration">
            <Button type="primary" text="Order orchestration" />
          </Link>
        </article>
      </section>
      <h3 className="support-header">Support information</h3>
      <section className="support-card">
        {information.length > 0 && informationCards()}
      </section>
    </main>
  );
};

export default Simulations;
