import {
  GET_COUNTRIES,
  GET_COUNTRIES_INFORMATION,
  SET_COUNTRIES,
  SET_COUNTRIES_INFORMATION,
  ADD_MEMBER_TO_COUNTRY,
  REMOVE_MEMBER_FROM_COUNTRY,
  ADD_ADMIN_TO_COUNTRY,
  REMOVE_ADMIN_FROM_COUNTRY,
  UPDATE_NODE_POOL,
  COUNTRY_TOAST_ADD,
  COUNTRY_TOAST_REMOVE,
  UPDATE_SELECTED_COUNTRY
} from '../constants';

const initialState = {
  countries: [],
  selectedCountry: null,
  countriesInformation: [],
  loadingCountries: false,
  countryToasts: []
};

export function countryReducer(state = initialState, action) {
  switch (action.type) {
    case GET_COUNTRIES:
      return { ...state, loadingCountries: true };
    case GET_COUNTRIES_INFORMATION:
      return { ...state };
    case SET_COUNTRIES:
      return { ...state, countries: action.payload.countries, loadingCountries: false };
    case SET_COUNTRIES_INFORMATION:
      return {
        ...state,
        countriesInformation: action.payload.countriesInformation
      };
    case UPDATE_SELECTED_COUNTRY:
      return { ...state, selectedCountry: action.payload.country };
    case ADD_MEMBER_TO_COUNTRY:
      return state;
    case REMOVE_MEMBER_FROM_COUNTRY:
      return state;
    case ADD_ADMIN_TO_COUNTRY:
      return state;
    case REMOVE_ADMIN_FROM_COUNTRY:
      return state;
    case UPDATE_NODE_POOL:
      return state;
    case COUNTRY_TOAST_ADD:
      return {
        ...state,
        countryToasts: [...state.countryToasts, action.payload]
      };
    case COUNTRY_TOAST_REMOVE:
      return {
        ...state,
        countryToasts: state.countryToasts.filter(s => s.name !== action.payload.country)
      };
    default:
      return state;
  }
}
