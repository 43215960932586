import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { jobReducer } from './reducers/jobReducer';
import { projectReducer } from './reducers/projectReducer';
import { simulationReducer } from './reducers/simulationReducer';
import { nodePoolReducer } from './reducers/nodePoolReducer';
import { scenarioReducer } from './reducers/scenarioReducer';
import { fileReducer } from './reducers/fileReducer';
import { userReducer } from './reducers/userReducer';
import { supportReducer } from './reducers/supportReducer';
import { statisticsReducer } from './reducers/statisticsReducer';
import { countryReducer } from './reducers/countryReducer';

const rootReducer = history =>
  combineReducers({
    simulations: simulationReducer,
    jobs: jobReducer,
    projects: projectReducer,
    scenarios: scenarioReducer,
    countries: countryReducer,
    files: fileReducer,
    user: userReducer,
    nodepools: nodePoolReducer,
    support: supportReducer,
    statistics: statisticsReducer,
    router: connectRouter(history)
  });

export default rootReducer;
