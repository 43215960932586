import {
  SET_PROJECTS,
  GET_PROJECTS,
  UPDATE_SELECTED_PROJECT,
  NEW_PROJECT,
  DELETE_PROJECT,
  PROJECT_TOAST_ADD,
  PROJECT_TOAST_REMOVE,
  UPDATE_PROJECT_SIMULATION
} from '../constants';

const initialState = {
  projects: [],
  selectedProject: null,
  loadingProjects: false,
  projectToasts: []
};

export function projectReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROJECTS:
      return { ...state, loadingProjects: true };
    case SET_PROJECTS:
      return {
        ...state,
        projects: action.payload.projects,
        loadingProjects: false
      };
    case NEW_PROJECT:
      return state;
    case DELETE_PROJECT:
      return state;
    case PROJECT_TOAST_ADD:
      return {
        ...state,
        projectToasts: [...state.projectToasts, action.payload]
      };
    case PROJECT_TOAST_REMOVE:
      return {
        ...state,
        projectToasts: state.projectToasts.filter(s => s.name !== action.payload.project)
      };
    case UPDATE_SELECTED_PROJECT:
      return { ...state, selectedProject: action.payload.project };
    case UPDATE_PROJECT_SIMULATION:
      return { ...state };
    default:
      return state;
  }
}
