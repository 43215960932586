import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@ingka/button';
import { useWindowSize, useKeyPress } from 'react-use';
import useCheckNodeContainsTarget from '../../../hooks/useCheckNodeContainsTarget';
import Breadcrumb from './Breadcrumb';
import logoImage from '../../../assets/logo.svg';
import './Header.scss';

const Header = ({
  authenticated,
  handleLogin,
  handleLogout,
  userName,
  permissions
}) => {
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const isPressed = useKeyPress('Escape');
  const { width } = useWindowSize();
  useEffect(() => {
    if (isPressed) setShowMenu(false);
  }, [isPressed]);

  const handleShowMenu = event => {
    event.persist();
    setShowMenu(!showMenu);
  };
  const [node] = useCheckNodeContainsTarget(event => {
    if (width < 750 && showMenu) {
      event.preventDefault();
      setShowMenu(false);
    }
  });

  return (
    <header className="header-content" data-testid="header">
      <div ref={node} className="header-wrapper">
        <img className="ikea-logo" src={logoImage} alt="IKEA Logotype" />
        <div className="mobile-header">
          <Breadcrumb />
          <button
            type="button"
            aria-label="hamburger menu button"
            className="mobile-menu-container"
            onClick={handleShowMenu}
          >
            <div className="hamburger-icon">
              <div className={`${showMenu ? 'toggle' : ''} line1`} />
              <div className={`${showMenu ? 'toggle' : ''} line2`} />
              <div className={`${showMenu ? 'toggle' : ''} line3`} />
            </div>
          </button>
        </div>
        <nav className={`header-links ${showMenu ? 'active' : ''}`}>
          <NavLink
            to="/"
            className="header-link"
            onClick={handleShowMenu}
            exact
            activeStyle={
              width > 750
                ? { borderBottom: '2px solid #0058a3' }
                : { borderBottom: '0px' }
            }
          >
            Home
          </NavLink>
          <NavLink
            to="/app/countries"
            exact
            className="header-link"
            activeStyle={
              width > 750
                ? { borderBottom: '2px solid #0058a3' }
                : { borderBottom: '0px' }
            }
            onClick={handleShowMenu}
          >
            Countries
          </NavLink>
          <NavLink
            to="/app/projects"
            className="header-link"
            onClick={handleShowMenu}
            activeStyle={
              width > 750
                ? { borderBottom: '2px solid #0058a3' }
                : { borderBottom: '0px' }
            }
          >
            Projects
          </NavLink>
          <NavLink
            to="/app/order-orchestration"
            className="header-link"
            onClick={handleShowMenu}
            activeStyle={
              width > 750
                ? { borderBottom: '2px solid #0058a3' }
                : { borderBottom: '0px' }
            }
          >
            Scenarios
          </NavLink>
          <NavLink
            to={{ pathname: '/app/jobs', previousLocation: location }}
            exact
            className="header-link"
            activeStyle={
              width > 750
                ? { borderBottom: '2px solid #0058a3' }
                : { borderBottom: '0px' }
            }
            onClick={handleShowMenu}
          >
            Jobs
          </NavLink>
          {permissions.canSeeStatsPage && (
            <NavLink
              to="/app/statistics"
              className="header-link"
              onClick={handleShowMenu}
              activeStyle={
                width > 750
                  ? { borderBottom: '2px solid #0058a3' }
                  : { borderBottom: '0px' }
              }
            >
              Statistics
            </NavLink>
          )}
          <button
            data-testid="login-button"
            type="button"
            className="header-link auth"
            onClick={authenticated ? handleLogout : handleLogin}
          >
            {authenticated ? 'Logout' : 'Login'}
          </button>
        </nav>
      </div>
      <div className="button-container">
        {authenticated && (
          <p className="user-info-paragraph">{`${userName}`}</p>
        )}
        <Button
          text={authenticated ? 'Logout' : 'Login'}
          small
          type="emphasised"
          onClick={authenticated ? handleLogout : handleLogin}
        />
      </div>
    </header>
  );
};

Header.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  handleLogin: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  userName: PropTypes.string,
  permissions: PropTypes.object.isRequired
};

Header.defaultProps = {
  userName: ''
};

export default Header;
