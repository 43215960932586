import { call, put, select, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import backendRequests from '../../api/backendRequests';
import * as selectors from '../selectors';
import { redirectAuth } from '../../utils/stateOauth';
import { initPermissions } from '../../utils/init-permissions';
import {
  getScenarios as getScenariosAction,
  setScenarios,
  updateSelectedScenario,
  addScenarioToast,
  removeScenarioToast
} from '../actions/scenarioActions';
import { updateAuthenticated } from '../actions/userActions';

export function* getScenarios(action) {
  try {
    const scenarios = yield call(backendRequests.fetchScenarios, action.payload.countryId, action.payload.projectId);
    yield put(setScenarios(scenarios.items));
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth(`/app/${action.payload.referrer}`);
      yield put(push(redirect));
    }
  }
}

export function* addNewScenario(action) {
  if (action.payload.projectId) {
    try {
      yield call(
        backendRequests.addScenario,
        action.payload.countryId,
        action.payload.projectId,
        action.payload.scenario
      );
      yield put(addScenarioToast(action.payload.scenario.name, 'confirmation', 'Scenario created!', null));
      yield put(getScenariosAction(action.payload.countryId, action.payload.projectId));
      yield delay(5000);
      yield put(removeScenarioToast(action.payload.scenario.name));
    } catch (error) {
      if (error.response.status === 403 || error.response.status === 401) {
        yield put(updateAuthenticated(false, initPermissions(null)));
        const redirect = redirectAuth(`/app/${action.payload.referrer}`);
        yield put(push(redirect));
      } else if (error.response.status === 400 || error.response.status === 404) {
        yield put(addScenarioToast(error.response.message, 'info-loud', 'Create scenario failed!', null));
        yield delay(5000);
        yield put(removeScenarioToast(error.response.message));
      } else if (error.response.status === 500) {
        yield put(addScenarioToast('Something went wrong', 'info-loud', 'Create scenario failed!', null));
        yield delay(5000);
        yield put(removeScenarioToast('Something went wrong'));
      }
    }
  }
}
export function* deleteScenario(action) {
  const selectedScenario = yield select(selectors.selectedScenario);
  try {
    yield call(
      backendRequests.deleteScenario,
      action.payload.countryId,
      action.payload.projectId,
      action.payload.scenario.id
    );
    yield put(addScenarioToast(action.payload.scenario.name, 'confirmation', 'Scenario deleted!', 'deleted'));
    yield put(getScenariosAction(action.payload.countryId, action.payload.projectId));
    if (selectedScenario?.id === action.payload.scenario.id) {
      yield put(updateSelectedScenario(null));
    }
    yield delay(5000);
    yield put(removeScenarioToast(action.payload.scenario.name));
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth('/app/projects');
      yield put(push(redirect));
    } else if (error.response.status === 400) {
      yield put(addScenarioToast(error.response.message, 'info-loud', 'Delete scenario failed!', null));
      yield delay(5000);
      yield put(removeScenarioToast(error.response.message));
    }
  }
}
