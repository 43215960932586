import { GET_JOB_STATISTICS, SET_JOB_STATISTICS } from '../constants';

const initialState = { perCountry: [] };

export function statisticsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_JOB_STATISTICS:
      return state;
    case SET_JOB_STATISTICS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
