import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import backendRequests from '../../api/backendRequests';
import { redirectAuth } from '../../utils/stateOauth';
import { initPermissions } from '../../utils/init-permissions';
import { setSimulations, setSimulationInformation } from '../actions/simulationActions';
import { updateAuthenticated } from '../actions/userActions';

export function* getSimulations() {
  try {
    const simulations = yield call(backendRequests.fetchSimulations);
    yield put(setSimulations(simulations.items));
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth('/app/projects/create');
      yield put(push(redirect));
    }
  }
}

export function* getSimulationInformation(action) {
  try {
    const simulationInformation = yield call(backendRequests.fetchSimulationInformation, action.payload.simulationId);
    yield put(setSimulationInformation(simulationInformation));
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth('/app/projects/create');
      yield put(push(redirect));
    }
  }
}
