import { GET_SIMULATIONS, SET_SIMULATIONS, GET_SIMULATION_INFORMATION, SET_SIMULATION_INFORMATION } from '../constants';

const initialState = {
  simulations: [],
  simulationInformation: {}
};

export function simulationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SIMULATIONS:
      return state;
    case SET_SIMULATIONS:
      return { ...state, simulations: action.payload.simulations };
    case GET_SIMULATION_INFORMATION:
      return state;
    case SET_SIMULATION_INFORMATION:
      return {
        ...state,
        simulationInformation: action.payload.simulationInformation
      };
    default:
      return state;
  }
}
