import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import * as createHistory from 'history';
import { routerMiddleware, ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import rootSaga from './store/sagas/sagas';
import rootReducer from './store/rootReducer';
import PublicRoutes from './routes/PublicRoutes';
import PrivateRoutes from './routes/PrivateRoutes';
import HeaderContainer from './components/containers/HeaderContainer';
import './styles/styles.scss';

const sagaMiddleware = createSagaMiddleware();
const history = createHistory.createBrowserHistory();

const store = createStore(
  rootReducer(history),
  compose(applyMiddleware(sagaMiddleware, logger, routerMiddleware(history)))
);

sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <div className="App">
      <ConnectedRouter history={history}>
        <HeaderContainer />
        <Suspense fallback={<div />}>
          <Switch>
            <Route path="/app" component={PrivateRoutes} />
            <Route path="/" component={PublicRoutes} />
          </Switch>
        </Suspense>
      </ConnectedRouter>
    </div>
  </Provider>,
  document.getElementById('root')
);
