import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Simulations from '../components/containers/Simulations';
import Authenticate from '../components/containers/Authenticate';
import NotFound from '../components/containers/NotFound';
import AuthenticateSuccess from '../components/containers/Authenticate/success';

const PublicRoutes = ({ match }) => {
  return (
    <div>
      <Switch>
        <Route exact path={`${match.path}`} component={Simulations} />
        <Route
          exact
          path={`${match.path}authenticate`}
          component={Authenticate}
        />
        <Route
          path={`${match.path}authenticate/success`}
          component={AuthenticateSuccess}
        />
        <Route path={`${match.path}404`} component={NotFound} />
        <Redirect to="/404" />
      </Switch>
    </div>
  );
};

PublicRoutes.propTypes = {
  match: PropTypes.object.isRequired
};
export default PublicRoutes;
