import React from 'react';
import './NotFound.scss';
import errorImage from '../../../assets/404-error.svg';

const OrderOrchestration = () => {
  return (
    <div className="not-found-container">
      <div className="full-page-message">
        <img className="extraclass" src={errorImage} alt="404 - page not found" />
        <h2 className="full-page-message__title">404 - page not found</h2>
        <p className="full-page-message__body">The page you tried to visit does not exist</p>
      </div>
    </div>
  );
};

export default OrderOrchestration;
