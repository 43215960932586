import {
  GET_PROJECTS,
  SET_PROJECTS,
  UPDATE_SELECTED_PROJECT,
  NEW_PROJECT,
  DELETE_PROJECT,
  PROJECT_TOAST_ADD,
  PROJECT_TOAST_REMOVE,
  UPDATE_PROJECT_SIMULATION
} from '../constants';

export function getProjects(country, referrer) {
  return {
    type: GET_PROJECTS,
    payload: { country, referrer }
  };
}

export function setProjects(projects) {
  return {
    type: SET_PROJECTS,
    payload: { projects }
  };
}

export function newProject(countryId, project) {
  return {
    type: NEW_PROJECT,
    payload: { countryId, project }
  };
}

export function deleteProject(countryId, project) {
  return {
    type: DELETE_PROJECT,
    payload: { countryId, project }
  };
}

export function addProjectToast(name, type, title, actionType) {
  return {
    type: PROJECT_TOAST_ADD,
    payload: { name, type, title, actionType }
  };
}

export function removeProjectToast(project) {
  return {
    type: PROJECT_TOAST_REMOVE,
    payload: { project }
  };
}

export function updateSelectedProject(project) {
  return {
    type: UPDATE_SELECTED_PROJECT,
    payload: { project }
  };
}

export function updateProjectSimulation(countryId, projectId, simulationId) {
  return {
    type: UPDATE_PROJECT_SIMULATION,
    payload: { countryId, projectId, simulationId }
  };
}
