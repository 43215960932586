import { AUTHENTICATED_STARTED, AUTHENTICATED_SUCCESSFUL, LOGOUT, UPDATE_AUTHENTICATED } from '../constants';

export function startLogin(state, redirect) {
  return {
    type: AUTHENTICATED_STARTED,
    payload: { state, redirect }
  };
}

export function successfulAuthenticated(state, idToken, nonce, upn, roles) {
  return {
    type: AUTHENTICATED_SUCCESSFUL,
    payload: { state, idToken, nonce, upn, roles }
  };
}

export function updateAuthenticated(authenticated, permissions) {
  return {
    type: UPDATE_AUTHENTICATED,
    payload: { authenticated, permissions }
  };
}

export function logout() {
  return {
    type: LOGOUT
  };
}
