export const SET_JOBS = 'SET_JOBS';
export const GET_JOBS = 'GET_JOBS';
export const GET_MORE_JOBS = 'GET_MORE_JOBS';
export const START_JOB = 'START_JOB';
export const STOP_JOB = 'STOP_JOB';
export const DELETE_JOB = 'DELETE_JOB';
export const GET_JOB = 'GET_JOB';
export const SET_CURSOR = 'SET_CURSOR';
export const UPDATE_SELECTED_JOB = 'UPDATE_SELECTED_JOB';
export const SET_SEARCH_INPUT = 'SET_SEARCH_INPUT';
export const GET_JOB_EVENTS = 'GET_JOB_EVENTS';
export const SET_JOB_EVENTS = 'SET_JOB_EVENTS';
export const JOB_TOAST_ADD = 'JOB_TOAST_ADD';
export const JOB_TOAST_REMOVE = 'JOB_TOAST_REMOVE';
export const GET_JOB_INPUT_FILES = 'GET_JOB_INPUT_FILES';
export const GET_JOB_OUTPUT_FILES = 'GET_JOB_OUTPUT_FILES';
export const SET_JOB_INPUT_FILES = 'SET_JOB_INPUT_FILES';
export const SET_JOB_OUTPUT_FILES = 'SET_JOB_OUTPUT_FILES';

export const GET_COUNTRIES = 'GET_COUNTRIES';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const GET_COUNTRIES_INFORMATION = 'GET_COUNTRIES_INFORMATION';
export const SET_COUNTRIES_INFORMATION = 'SET_COUNTRIES_INFORMATION';
export const ADD_MEMBER_TO_COUNTRY = 'ADD_MEMBER_TO_COUNTRY';
export const REMOVE_MEMBER_FROM_COUNTRY = 'REMOVE_MEMBER_FROM_COUNTRY';
export const ADD_ADMIN_TO_COUNTRY = 'ADD_ADMIN_TO_COUNTRY';
export const REMOVE_ADMIN_FROM_COUNTRY = 'REMOVE_ADMIN_FROM_COUNTRY';
export const UPDATE_SELECTED_COUNTRY = 'UPDATE_SELECTED_COUNTRY';
export const UPDATE_NODE_POOL = 'UPDATE_NODE_POOL';
export const COUNTRY_TOAST_ADD = 'COUNTRY_TOAST_ADD';
export const COUNTRY_TOAST_REMOVE = 'COUNTRY_TOAST_REMOVE';

export const GET_PROJECTS = 'GET_PROJECTS';
export const SET_PROJECTS = 'SET_PROJECTS';
export const NEW_PROJECT = 'NEW_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const PROJECT_TOAST_ADD = 'PROJECT_TOAST_ADD';
export const PROJECT_TOAST_REMOVE = 'PROJECT_TOAST_REMOVE';
export const UPDATE_SELECTED_PROJECT = 'UPDATE_SELECTED_PROJECT';
export const UPDATE_PROJECT_SIMULATION = 'UPDATE_PROJECT_SIMULATION';

export const GET_SCENARIOS = 'GET_SCENARIOS';
export const SET_SCENARIOS = 'SET_SCENARIOS';
export const NEW_SCENARIO = 'NEW_SCENARIO';
export const DELETE_SCENARIO = 'DELETE_SCENARIO';
export const SCENARIO_TOAST_ADD = 'SCENARIO_TOAST_ADD';
export const SCENARIO_TOAST_REMOVE = 'SCENARIO_TOAST_REMOVE';
export const UPDATE_SELECTED_SCENARIO = 'UPDATE_SELECTED_SCENARIO';

export const GET_INPUT_FILES = 'GET_INPUT_FILES';
export const GET_OUTPUT_FILES = 'GET_OUTPUT_FILES';
export const SET_INPUT_FILES = 'SET_INPUT_FILES';
export const SET_OUTPUT_FILES = 'SET_OUTPUT_FILES';
export const UPLOAD_FILE_START = 'UPLOAD_FILE_START';
export const FILE_TOAST_ADD = 'FILE_TOAST_ADD';
export const FILE_TOAST_REMOVE = 'FILE_TOAST_REMOVE';

export const AUTHENTICATED_STARTED = 'AUTHENTICATED_STARTED';
export const AUTHENTICATED_SUCCESSFUL = 'AUTHENTICATED_SUCCESSFUL';
export const UPDATE_AUTHENTICATED = 'UPDATE_AUTHENTICATED';
export const LOGOUT = 'LOGOUT';

export const GET_SIMULATIONS = 'GET_SIMULATIONS';
export const SET_SIMULATIONS = 'SET_SIMULATIONS';
export const GET_SIMULATION_INFORMATION = 'GET_SIMULATION_INFORMATION';
export const SET_SIMULATION_INFORMATION = 'SET_SIMULATION_INFORMATION';

export const GET_NODEPOOL = 'GET_NODEPOOL';
export const SET_NODEPOOL = 'SET_NODEPOOL';
export const GET_NODEPOOLS = 'GET_NODEPOOLS';
export const SET_NODEPOOLS = 'SET_NODEPOOLS';

export const GET_SUPPORT_INFORMATION = 'GET_SUPPORT_INFORMATION';
export const SET_SUPPORT_INFORMATION = 'SET_SUPPORT_INFORMATION';

export const GET_JOB_STATISTICS = 'GET_JOB_STATISTICS';
export const SET_JOB_STATISTICS = 'SET_JOB_STATISTICS';
