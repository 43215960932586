export const encodeCurrentState = state => {
  return window.btoa(JSON.stringify(state));
};

export const decodeResponse = encodedState => {
  const splittedOnce = encodedState.split('&');
  const splittedTwice = splittedOnce.map(subStr => {
    return subStr.split('=');
  });
  const decodedState = {
    id_token: splittedTwice[0][1],
    state: window.atob(decodeURIComponent(splittedTwice[1][1])),
    session_state: splittedTwice[2][1]
  };
  return decodedState;
};

/* Extract nonce, upn and roles from the JWT body */
export const decodeJWT = encodedJWT => {
  const JWT = encodedJWT.split('.');
  let jwtBody = JWT[1];
  jwtBody = JSON.parse(window.atob(jwtBody));
  const decodedJWT = {
    nonce: jwtBody.nonce,
    upn: jwtBody.upn,
    roles: jwtBody.roles
  };
  return decodedJWT;
};

/*
  check if JWT has expired, and if it is expired
  return the authentication path with the referrer as state
*/
export const redirectAuth = referrer => {
  const now = Date.now().valueOf() / 1000;
  const jwt = localStorage.getItem('JWT');
  if (jwt) {
    const JWT = jwt.split('.');
    const jwtBody = JSON.parse(window.atob(JWT[1]));
    if (typeof jwtBody.exp !== 'undefined' && jwtBody.exp < now) {
      return {
        pathname: '/authenticate',
        state: {
          referrer
        }
      };
    }
    if (typeof jwtBody.nbf !== 'undefined' && jwtBody.nbf > now) {
      return {
        pathname: '/authenticate',
        state: {
          referrer
        }
      };
    }
  }
  return '/';
};

export const isCountryAdmin = country => {
  return country.admins.some(admin => admin.email === localStorage.getItem('upn'));
};
