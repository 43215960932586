import { GET_SIMULATIONS, SET_SIMULATIONS, GET_SIMULATION_INFORMATION, SET_SIMULATION_INFORMATION } from '../constants';

export function getSimulations() {
  return {
    type: GET_SIMULATIONS
  };
}

export function setSimulations(simulations) {
  return {
    type: SET_SIMULATIONS,
    payload: { simulations }
  };
}

export function getSimulationInformation(simulationId) {
  return {
    type: GET_SIMULATION_INFORMATION,
    payload: { simulationId }
  };
}

export function setSimulationInformation(simulationInformation) {
  return {
    type: SET_SIMULATION_INFORMATION,
    payload: { simulationInformation }
  };
}
