import {
  SET_JOBS,
  GET_JOBS,
  GET_MORE_JOBS,
  SET_CURSOR,
  START_JOB,
  STOP_JOB,
  DELETE_JOB,
  GET_JOB,
  UPDATE_SELECTED_JOB,
  SET_SEARCH_INPUT,
  GET_JOB_EVENTS,
  SET_JOB_EVENTS,
  JOB_TOAST_ADD,
  JOB_TOAST_REMOVE,
  GET_JOB_INPUT_FILES,
  GET_JOB_OUTPUT_FILES,
  SET_JOB_INPUT_FILES,
  SET_JOB_OUTPUT_FILES
} from '../constants';

const initialState = {
  jobs: [],
  loadingJobs: false,
  loadingSingleJob: false,
  loadingMore: false,
  loadingJobInputFiles: false,
  loadingJobOutputFiles: false,
  selectedJob: null,
  cursor: null,
  searchInput: {},
  events: [],
  jobToasts: [],
  jobFiles: {
    input: [],
    output: []
  }
};

export function jobReducer(state = initialState, action) {
  switch (action.type) {
    case GET_JOBS:
      return { ...state, loadingJobs: true };
    case GET_MORE_JOBS:
      return { ...state, loadingMore: true };
    case SET_JOBS:
      return {
        ...state,
        jobs: action.payload.jobs,
        loadingJobs: false,
        loadingMore: false
      };
    case GET_JOB:
      return { ...state, loadingSingleJob: true };
    case SET_CURSOR:
      return { ...state, cursor: action.payload.cursor };
    case SET_SEARCH_INPUT:
      return { ...state, searchInput: action.payload.searchInput };
    case UPDATE_SELECTED_JOB:
      return { ...state, selectedJob: action.payload.job, loadingSingleJob: false };
    case START_JOB:
      return { ...state };
    case JOB_TOAST_ADD:
      return { ...state, jobToasts: [...state.jobToasts, action.payload] };
    case JOB_TOAST_REMOVE:
      return {
        ...state,
        jobToasts: state.jobToasts.filter(s => s.name !== action.payload.name)
      };
    case STOP_JOB:
      return { ...state };
    case DELETE_JOB:
      return { ...state };
    case GET_JOB_EVENTS:
      return { ...state, loadingSingleJob: true };
    case SET_JOB_EVENTS:
      return { ...state, loadingSingleJob: false, events: action.payload.events };
    default:
      return state;
    case GET_JOB_INPUT_FILES:
      return { ...state, loadingJobInputFiles: true };
    case GET_JOB_OUTPUT_FILES:
      return { ...state, loadingJobOutputFiles: true };
    case SET_JOB_INPUT_FILES:
      return {
        ...state,
        jobFiles: { ...state.jobFiles, input: action.payload.files },
        loadingJobInputFiles: false
      };
    case SET_JOB_OUTPUT_FILES:
      return {
        ...state,
        jobFiles: { ...state.jobFiles, output: action.payload.files },
        loadingJobOutputFiles: false
      };
  }
}
