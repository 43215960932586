import { authHeader } from '../utils/auth-header';

function getResponse(response) {
  return new Promise(resolve =>
    response.json().then(json =>
      resolve({
        status: response.status,
        ok: response.ok,
        json
      })
    )
  );
}
function parseJSON(response) {
  return response.json().catch(err => {
    // eslint-disable-next-line no-console
    console.error(`${err} happend`);
    return {};
  });
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(response.status);
  if (response.status === 401 || response.status === 403) {
    error.response = response;
    throw error;
  }
  if (response.status === 400 || response.status === 404 || response.status === 500) {
    return getResponse(response).then(r => {
      error.response = r.json;
      throw error;
    });
  }
  return null;
}

/*
  Used for creating the query string used for filtering jobs,
  can be made up by one or more of country, project, scenario and status.
  check if a seach has been done for an input then combine with the query parameter
  for example if a search for country Sweden is done add country=Sweden and then
  join all values together to create the queryString. Check if there is a cursor
  if there is a cursor it is appended before the search in the network query and therefore
  use &, if not the queryString should start with ?.
*/
export function getQueryString(searchInput, hasCursor) {
  const prefix = hasCursor ? '&' : '?';
  const queryString = Object.entries(searchInput)
    .reduce((acc, input) => {
      const [key, value] = [input[0], input[1]];
      if (value.length > 0) {
        acc.push(`${key}=${value}`);
      }
      return acc;
    }, [])
    .join('&');
  return prefix.concat(queryString);
}

export function request(url) {
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: authHeader(),
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  })
    .then(checkStatus)
    .then(parseJSON);
}

export function post(url, body) {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authHeader()
    },
    body: JSON.stringify(body)
  })
    .then(checkStatus)
    .then(parseJSON);
}

export function put(url, body) {
  return fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authHeader()
    },
    body: JSON.stringify(body)
  })
    .then(checkStatus)
    .then(parseJSON);
}

export function remove(url) {
  return fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authHeader()
    }
  })
    .then(checkStatus)
    .then(parseJSON);
}

export function download(url, { file }) {
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: authHeader()
    }
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(ref => {
      const link = document.createElement('a');
      link.download = file;
      link.href = ref.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
}

export function upload(url, file) {
  const formData = new FormData();
  formData.append('file', file);
  return fetch(url, {
    method: 'PUT',
    headers: { Authorization: authHeader() },
    body: formData
  })
    .then(checkStatus)
    .then(parseJSON);
}
