import { call, put, select, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import backendRequests from '../../api/backendRequests';
import * as selectors from '../selectors';
import { redirectAuth } from '../../utils/stateOauth';
import { initPermissions } from '../../utils/init-permissions';
import { setInputFiles, setOutputFiles, addFileToast, removeFileToast } from '../actions/fileActions';
import { updateAuthenticated } from '../actions/userActions';

export function* getInputFiles(action) {
  try {
    const files = yield call(
      backendRequests.fetchFiles,
      action.payload.countryId,
      action.payload.projectId,
      action.payload.scenarioId,
      'input'
    );
    yield put(setInputFiles(files.items.map(file => file.name)));
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth('/app/order-orchestration');
      yield put(push(redirect));
    }
  }
}

export function* getOutputFiles(action) {
  try {
    const files = yield call(
      backendRequests.fetchFiles,
      action.payload.countryId,
      action.payload.projectId,
      action.payload.scenarioId,
      'output'
    );
    yield put(setOutputFiles(files.items.map(file => file.name)));
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth('/app/order-orchestration');
      yield put(push(redirect));
    }
  }
}

export function* uploadFile(action) {
  const selectedCountry = yield select(selectors.selectedCountry);
  const selectedProject = yield select(selectors.selectedProject);
  const selectedScenario = yield select(selectors.selectedScenario);
  if (selectedCountry && selectedProject && selectedScenario) {
    try {
      yield call(
        backendRequests.uploadFile,
        selectedCountry.id,
        selectedProject.id,
        selectedScenario.id,
        action.payload.file
      );
      yield put(addFileToast(action.payload.file.name, 'confirmation', 'New file uploaded!'));
      const files = yield call(
        backendRequests.fetchFiles,
        selectedCountry.id,
        selectedProject.id,
        selectedScenario.id,
        'input'
      );
      yield delay(5000);
      yield put(removeFileToast(action.payload.file.name));
      yield put(setInputFiles(files.items.map(file => file.name)));
    } catch (error) {
      if (error.response.status === 403 || error.response.status === 401) {
        yield put(updateAuthenticated(false, initPermissions(null)));
        const redirect = redirectAuth('/app/order-orchestration');
        yield put(push(redirect));
      } else if (error.response.status === 400 || error.response.status === 404) {
        yield put(addFileToast(error.response.message, 'info-loud', 'New file upload failed!'));
        yield delay(5000);
        yield put(removeFileToast(error.response.message));
      } else if (error.response.status === 500) {
        yield put(addFileToast('Something went wrong', 'info-loud', 'New file upload failed!'));
        yield delay(5000);
        yield put(removeFileToast('Something went wrong'));
      }
    }
  }
}
