import React, { lazy } from 'react';

const Jobs = lazy(() => import('../components/containers/Jobs'));
const JobItem = lazy(() => import('../components/containers/JobItem'));
const Projects = lazy(() => import('../components/containers/Projects'));
const NewProject = lazy(() => import('../components/containers/NewProject'));
const OrderOrchestration = lazy(() =>
  import('../components/containers/OrderOrchestration')
);
const Countries = lazy(() => import('../components/containers/Countries'));
const NotRegistered = lazy(() =>
  import('../components/containers/NotRegistered')
);
const Statistics = lazy(() => import('../components/containers/Statistics'));

const ProjectsPage = () => {
  return <Projects />;
};

const NewProjectPage = () => {
  return <NewProject />;
};

const ScenariosPage = () => {
  return <OrderOrchestration />;
};

const JobsPage = () => {
  return <Jobs />;
};

const JobItemPage = () => {
  return <JobItem />;
};

const OrderOrchestrationPage = () => {
  return <OrderOrchestration />;
};

const CountriesPage = () => {
  return <Countries />;
};
const NotRegisteredPage = () => {
  return <NotRegistered />;
};

const StatisticsPage = () => {
  return <Statistics />;
};

export {
  ProjectsPage,
  NewProjectPage,
  ScenariosPage,
  JobsPage,
  JobItemPage,
  OrderOrchestrationPage,
  CountriesPage,
  NotRegisteredPage,
  StatisticsPage
};
