export const selectedCountry = state => state.countries.selectedCountry;
export const selectedProject = state => state.projects.selectedProject;
export const selectedScenario = state => state.scenarios.selectedScenario;
export const projects = state => state.projects.projects;
export const projectsInformation = state => state.projects.projectsInformation;
export const cursor = state => state.jobs.cursor;
export const jobs = state => state.jobs.jobs;
export const permissions = state => state.user.permissions;
export const searchInput = state => state.jobs.searchInput;
export const selectedJob = state => state.jobs.selectedJob;
export const statistics = state => state.jobs.statistics;
