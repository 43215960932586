import { call, put, select, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import backendRequests from '../../api/backendRequests';
import * as selectors from '../selectors';
import { redirectAuth } from '../../utils/stateOauth';
import { initPermissions } from '../../utils/init-permissions';
import {
  getJobs as getJobsAction,
  getJob as getJobAction,
  setJobs,
  setSearchInput,
  setCursor,
  updateSelectedJob,
  setJobEvents,
  addJobToast,
  removeJobToast,
  setJobInputFiles,
  setJobOutputFiles
} from '../actions/jobActions';
import { updateAuthenticated } from '../actions/userActions';

export function* getJobs(action) {
  try {
    const jobs = action.payload.searchParam
      ? yield call(backendRequests.fetchJobsWithFilter, action.payload.searchParam)
      : yield call(backendRequests.fetchJobs);
    if (action.payload.searchParam) yield put(setSearchInput(action.payload.searchParam));
    yield put(setJobs(jobs.items));
    yield put(setCursor(jobs.cursor));
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth('/app/jobs');
      yield put(push(redirect));
    }
  }
}

export function* getMoreJobs() {
  const cursor = yield select(selectors.cursor);
  const currentJobs = yield select(selectors.jobs);
  const searchInput = yield select(selectors.searchInput);
  try {
    const jobs = yield call(backendRequests.fetchJobsWithCursor, cursor, searchInput);
    yield put(setJobs(currentJobs.concat(jobs.items)));
    yield put(setCursor(jobs.cursor));
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth('/app/jobs');
      yield put(push(redirect));
    }
  }
}

export function* getJob(action) {
  try {
    const job = yield call(backendRequests.fetchJob, action.payload.jobId);
    yield put(updateSelectedJob(job));
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth(`/jobs/${action.payload.jobId}`);
      yield put(push(redirect));
    }
  }
}

export function* startSimulationJob() {
  const selectedCountry = yield select(selectors.selectedCountry);
  const selectedProject = yield select(selectors.selectedProject);
  const selectedScenario = yield select(selectors.selectedScenario);
  if (selectedCountry && selectedProject && selectedScenario) {
    try {
      yield call(backendRequests.startJob, selectedCountry.id, selectedProject.id, selectedScenario.id);
      yield put(addJobToast(selectedScenario.name, 'confirmation', 'Started job!', 'started'));
      yield put(push('/app/jobs'));
      yield delay(5000);
      yield put(removeJobToast(selectedScenario.name));
    } catch (error) {
      if (error.response.status === 403 || error.response.status === 401) {
        yield put(updateAuthenticated(false, initPermissions(null)));
        const redirect = redirectAuth('/app/order-orchestration');
        yield put(push(redirect));
      } else if (error.response.status === 400 || error.response.status === 404) {
        yield put(addJobToast(error.response.message, 'info-loud', 'Starting job failed!', 'started'));
        yield delay(5000);
        yield put(removeJobToast(error.response.message));
      } else if (error.response.status === 500) {
        yield put(addJobToast('Something went wrong', 'info-loud', 'Starting job failed!', 'started'));
        yield delay(5000);
        yield put(removeJobToast('Something went wrong'));
      }
    }
  }
}

export function* stopSimulationJob(action) {
  try {
    yield call(backendRequests.stopJob, action.payload.jobId);
    yield put(addJobToast(`job: ${action.payload.jobId}`, 'confirmation', 'Stopped job!', 'stopped'));
    yield put(getJobAction(action.payload.jobId));
    yield delay(5000);
    yield put(removeJobToast(`job: ${action.payload.jobId}`));
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth(`/app/jobs/${action.payload.jobId}`);
      yield put(push(redirect));
    } else if (error.response.status === 400) {
      yield put(addJobToast(error.response.message, 'info-loud', 'Stopping job failed!', 'stopped'));
      yield delay(5000);
      yield put(removeJobToast(error.response.message));
    }
  }
}

export function* deleteSimulationJob(action) {
  try {
    yield call(backendRequests.deleteJob, action.payload.jobId);
    yield put(addJobToast(`job: ${action.payload.jobId}`, 'confirmation', 'Deleted job!', 'deleted'));
    yield put(push('/app/jobs'));
    yield put(getJobsAction());
    yield delay(5000);
    yield put(removeJobToast(`job: ${action.payload.jobId}`));
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth(`/app/jobs/${action.payload.jobId}`);
      yield put(push(redirect));
    } else if (error.response.status === 400) {
      yield put(addJobToast(error.response.message, 'info-loud', 'Deleting job failed!', 'deleted'));
      yield delay(5000);
      yield put(removeJobToast(error.response.message));
    }
  }
}

export function* getJobEvents(action) {
  try {
    const events = yield call(backendRequests.fetchJobEvents, action.payload.jobId);
    yield put(setJobEvents(events.items));
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth(`/app/jobs/${action.payload.jobId}`);
      yield put(push(redirect));
    }
  }
}

export function* getJobInputFiles(action) {
  try {
    const files = yield call(backendRequests.fetchJobFiles, action.payload.jobId, 'input');
    yield put(setJobInputFiles(files.items.map(file => file.name)));
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth(`/app/jobs/${action.payload.jobId}`);
      yield put(push(redirect));
    }
  }
}

export function* getJobOutputFiles(action) {
  try {
    const files = yield call(backendRequests.fetchJobFiles, action.payload.jobId, 'output');
    yield put(setJobOutputFiles(files.items.map(file => file.name)));
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth(`/app/jobs/${action.payload.jobId}`);
      yield put(push(redirect));
    }
  }
}
