import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './Breadcrumb.scss';

const Breadcrumb = () => {
  const location = useLocation();
  const breadCrumbText = path => {
    switch (path) {
      case 'countries':
        return 'Countries';
      case 'projects':
        return 'Projects';
      case 'order-orchestration':
        return 'Scenarios';
      case 'jobs':
        return 'Jobs';
      case 'create':
        return 'Create';
      case 'statistics':
        return 'Stats';
      default:
        return 'Item';
    }
  };

  const getParentRoute = (paths, position) => {
    let completePath = '';
    paths.slice(0, position + 1).forEach(path => {
      completePath += `${path}/`;
    });
    return completePath;
  };

  const renderBreadCrumb = (paths, path, index) => {
    return paths[index + 1] ? (
      <NavLink
        data-testid="breadcrumb-item"
        key={path}
        to={`/app/${getParentRoute(paths, index)}`}
        className="breadcrumb link"
      >
        {breadCrumbText(path)}
      </NavLink>
    ) : (
      <span
        data-testid="breadcrumb-item"
        key={path}
        className="breadcrumb text"
      >
        <p>{breadCrumbText(path)}</p>
      </span>
    );
  };

  const getActivePage = () => {
    if (location.pathname === '/') {
      return (
        <span className="breadcrumb text">
          <p>Home</p>
        </span>
      );
    }
    const paths = location.pathname
      .split('/')
      .filter(part => part !== 'app' && part !== '');
    return paths.map((path, index) => renderBreadCrumb(paths, path, index));
  };

  return <div className="breadcrumb-wrapper">{getActivePage()}</div>;
};

export default Breadcrumb;
