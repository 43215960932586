// Role based permissions that are initiated when a user is authenticated
export const initPermissions = role => {
  return {
    canViewAdminPage: role === 'cff_simulations_admin',
    canEditCountryUsers: role === 'cff_simulations_admin',
    canEditScenarioList: role === 'cff_simulations_admin' || role === 'cff_simulations_user',
    canCreateNewProject: role === 'cff_simulations_admin',
    canDeleteProjects: role === 'cff_simulations_admin',
    canChangeProjectSimulation: role === 'cff_simulations_admin',
    canSeeStatsPage: role === 'cff_simulations_admin'
  };
};
