import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import Header from '../../common/Header';
import {
  logout,
  updateAuthenticated
} from '../../../store/actions/userActions';
import { initPermissions } from '../../../utils/init-permissions';
import './HeaderContainer.scss';

const HeaderContainer = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { authenticated, permissions } = useSelector(state => state.user);
  const userName = localStorage.getItem('upn');
  const handleLogout = () => {
    dispatch(logout());
    history.push('/');
  };
  const handleLogin = () => {
    history.push({
      pathname: '/authenticate',
      state: {
        referrer: location.pathname
      }
    });
  };

  /*
    Check if the user is still authenticated when the location changes,
    do not perform the check when the user in the middle of the authentication process.
  */
  useEffect(() => {
    if (
      location.pathname !== '/authenticate' &&
      location.pathname !== '/authenticate/success'
    ) {
      const role = localStorage.getItem('roles');
      const jwt = localStorage.getItem('JWT');
      if (jwt && role) {
        const updatedPermissions = initPermissions(role);
        dispatch(updateAuthenticated(true, updatedPermissions));
      }
    }
  }, [dispatch, location]);

  return (
    <div className="ikea-container">
      <Header
        permissions={permissions}
        authenticated={authenticated}
        handleLogin={handleLogin}
        handleLogout={handleLogout}
        userName={userName}
      />
    </div>
  );
};

export default HeaderContainer;
