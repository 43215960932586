import { useEffect, useRef, useCallback } from 'react';

const useCheckNodeContainsTarget = callback => {
  const node = useRef();

  const handleClick = useCallback(
    e => {
      if (node.current.contains(e.target)) {
        return;
      }
      callback(e);
    },
    [callback, node]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);

  return [node];
};

export default useCheckNodeContainsTarget;
