import { GET_NODEPOOL, SET_NODEPOOL, GET_NODEPOOLS, SET_NODEPOOLS } from '../constants';

const initialState = { nodePools: [], nodePoolInformation: {} };

export function nodePoolReducer(state = initialState, action) {
  switch (action.type) {
    case GET_NODEPOOLS:
      return state;
    case SET_NODEPOOLS:
      return { ...state, nodePools: action.payload.nodePools };
    case GET_NODEPOOL:
      return state;
    case SET_NODEPOOL:
      return {
        ...state,
        nodePoolInformation: {
          ...state.nodePoolInformation,
          [action.payload.countryId]: action.payload.nodePoolInformation
        }
      };
    default:
      return state;
  }
}
