import { GET_JOB_STATISTICS, SET_JOB_STATISTICS } from '../constants';

export function getJobStatistics(startDate, endDate) {
  return {
    type: GET_JOB_STATISTICS,
    payload: { startDate, endDate }
  };
}

export function setJobStatistics(statistics) {
  return {
    type: SET_JOB_STATISTICS,
    payload: { ...statistics }
  }
}
