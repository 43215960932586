import { call, put, select, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import backendRequests from '../../api/backendRequests';
import * as selectors from '../selectors';
import { redirectAuth } from '../../utils/stateOauth';
import { initPermissions } from '../../utils/init-permissions';
import { setProjects, updateSelectedProject, addProjectToast, removeProjectToast } from '../actions/projectActions';
import { updateSelectedScenario } from '../actions/scenarioActions';
import { updateAuthenticated } from '../actions/userActions';

export function* getProjects(action) {
  try {
    const projects = yield call(backendRequests.fetchProjects, action.payload.country);
    yield put(setProjects(projects.items));
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth(`/app/${action.payload.referrer}`);
      yield put(push(redirect));
    }
  }
}

export function* addNewProject(action) {
  try {
    yield call(backendRequests.createProject, action.payload.countryId, action.payload.project);
    yield put(addProjectToast(action.payload.project.name, 'confirmation', 'New project created!', 'created'));
    yield put(push('/app/projects'));
    yield delay(5000);
    yield put(removeProjectToast(action.payload.project.name));
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth('/app/projects/create');
      yield put(push(redirect));
    } else if (error.response.status === 400) {
      yield put(addProjectToast(error.response.message, 'info-loud', 'New project failed!'));
      yield delay(5000);
      yield put(removeProjectToast(error.response.message));
    }
  }
}

export function* removeProject(action) {
  const selectedProject = yield select(selectors.selectedProject);
  try {
    yield call(backendRequests.deleteProject, action.payload.countryId, action.payload.project.id);
    const updatedProjects = yield call(backendRequests.fetchProjects, action.payload.countryId);
    yield put(setProjects(updatedProjects.items));
    yield put(addProjectToast(action.payload.project.name, 'confirmation', 'Project deleted!', 'deleted'));
    if (selectedProject?.id === action.payload.project.id) {
      yield put(updateSelectedProject(null));
      yield put(updateSelectedScenario(null));
    }
    yield delay(5000);
    yield put(removeProjectToast(action.payload.project.name));
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth('/app/projects');
      yield put(push(redirect));
    } else if (error.response.status === 400) {
      yield put(addProjectToast(error.response.message, 'info-loud', 'Delete project failed!'));
      yield delay(5000);
      yield put(removeProjectToast(error.response.message));
    }
  }
}

export function* updateProjectSimulation(action) {
  try {
    yield call(
      backendRequests.updateProjectSimulation,
      action.payload.countryId,
      action.payload.projectId,
      action.payload.simulationId
    );
    const projects = yield call(backendRequests.fetchProjects, action.payload.countryId);
    yield put(setProjects(projects.items));
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth('/app/projects');
      yield put(push(redirect));
    }
  }
}
