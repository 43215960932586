import {
  SET_SCENARIOS,
  GET_SCENARIOS,
  UPDATE_SELECTED_SCENARIO,
  NEW_SCENARIO,
  DELETE_SCENARIO,
  SCENARIO_TOAST_ADD,
  SCENARIO_TOAST_REMOVE
} from '../constants';

const initialState = {
  scenarios: [],
  selectedScenario: null,
  scenarioToasts: [],
  loadingScenarios: false
};

export function scenarioReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SCENARIOS:
      return { ...state, loadingScenarios: true };
    case SET_SCENARIOS:
      return {
        ...state,
        scenarios: action.payload.scenarios,
        loadingScenarios: false
      };
    case UPDATE_SELECTED_SCENARIO:
      return { ...state, selectedScenario: action.payload.scenario };
    case NEW_SCENARIO:
      return { ...state };
    case DELETE_SCENARIO:
      return { ...state };
    case SCENARIO_TOAST_ADD:
      return {
        ...state,
        scenarioToasts: [...state.scenarioToasts, action.payload]
      };
    case SCENARIO_TOAST_REMOVE:
      return {
        ...state,
        scenarioToasts: state.scenarioToasts.filter(s => s.name !== action.payload.scenario)
      };
    default:
      return state;
  }
}
