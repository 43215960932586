import { GET_NODEPOOLS, SET_NODEPOOLS, GET_NODEPOOL, SET_NODEPOOL } from '../constants';

export function getNodePools() {
  return {
    type: GET_NODEPOOLS
  };
}

export function setNodePools(nodePools) {
  return {
    type: SET_NODEPOOLS,
    payload: { nodePools }
  };
}

export function getNodePoolInformation(countryId, nodePoolId) {
  return {
    type: GET_NODEPOOL,
    payload: { countryId, nodePoolId }
  };
}

export function setNodePoolInformation(countryId, nodePoolInformation) {
  return {
    type: SET_NODEPOOL,
    payload: { countryId, nodePoolInformation }
  };
}
