import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import backendRequests from '../../api/backendRequests';
import { updateSelectedProject } from '../actions/projectActions';
import { updateSelectedScenario } from '../actions/scenarioActions';
import { updateAuthenticated } from '../actions/userActions';
import { initPermissions } from '../../utils/init-permissions';

export function* startLogin(action) {
  const nonce = Math.round(Math.random() * 1000000);
  const redirectUri = `${action.payload.redirect}/authenticate/success`;
  yield localStorage.setItem('nonce', nonce);
  yield call(backendRequests.authenticate, action.payload.state, nonce, redirectUri);
}

export function* successfulLogin(action) {
  const nonce = yield call([localStorage, 'getItem'], 'nonce');
  if (nonce === action.payload.nonce) {
    yield call([localStorage, 'setItem'], 'JWT', action.payload.idToken);
    yield call([localStorage, 'setItem'], 'upn', action.payload.upn);
    yield call([localStorage, 'setItem'], 'roles', action.payload.roles);
    yield put(updateSelectedProject(action.payload.state.selectedProject));
    yield put(updateSelectedScenario(action.payload.state.selectedScenario));
    const permissions = initPermissions(action.payload.roles[0]);
    yield put(updateAuthenticated(true, permissions));
    yield put(push(`${action.payload.state.referrer}`));
  }
}

export function* logout() {
  yield localStorage.clear();
}
