import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import backendRequests from '../../api/backendRequests';
import { redirectAuth } from '../../utils/stateOauth';
import { setSupportInformation } from '../actions/supportActions';
import { updateAuthenticated } from '../actions/userActions';
import { initPermissions } from '../../utils/init-permissions';

export function* getSupportInformation() {
  try {
    const information = yield call(backendRequests.fetchSupportInformation);
    yield put(setSupportInformation(information.items));
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth('/');
      yield put(push(redirect));
    }
  }
}
