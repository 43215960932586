import {
  SET_SCENARIOS,
  GET_SCENARIOS,
  UPDATE_SELECTED_SCENARIO,
  NEW_SCENARIO,
  DELETE_SCENARIO,
  SCENARIO_TOAST_ADD,
  SCENARIO_TOAST_REMOVE
} from '../constants';

export function getScenarios(countryId, projectId, referrer) {
  return {
    type: GET_SCENARIOS,
    payload: { countryId, projectId, referrer }
  };
}

export function setScenarios(scenarios) {
  return {
    type: SET_SCENARIOS,
    payload: { scenarios }
  };
}

export function updateSelectedScenario(scenario) {
  return {
    type: UPDATE_SELECTED_SCENARIO,
    payload: { scenario }
  };
}

export function newScenario(countryId, projectId, scenario, referrer) {
  return {
    type: NEW_SCENARIO,
    payload: { countryId, projectId, scenario, referrer }
  };
}

export function deleteScenario(countryId, projectId, scenario) {
  return {
    type: DELETE_SCENARIO,
    payload: { countryId, projectId, scenario }
  };
}

export function addScenarioToast(name, type, title, actionType) {
  return {
    type: SCENARIO_TOAST_ADD,
    payload: { name, type, title, actionType }
  };
}

export function removeScenarioToast(scenario) {
  return {
    type: SCENARIO_TOAST_REMOVE,
    payload: { scenario }
  };
}
