import { GET_SUPPORT_INFORMATION, SET_SUPPORT_INFORMATION } from '../constants';

export function getSupportInformation() {
  return {
    type: GET_SUPPORT_INFORMATION
  };
}

export function setSupportInformation(information) {
  return {
    type: SET_SUPPORT_INFORMATION,
    payload: { information }
  };
}
