import {
  GET_COUNTRIES,
  GET_COUNTRIES_INFORMATION,
  SET_COUNTRIES,
  SET_COUNTRIES_INFORMATION,
  ADD_MEMBER_TO_COUNTRY,
  REMOVE_MEMBER_FROM_COUNTRY,
  ADD_ADMIN_TO_COUNTRY,
  REMOVE_ADMIN_FROM_COUNTRY,
  UPDATE_NODE_POOL,
  UPDATE_SELECTED_COUNTRY,
  COUNTRY_TOAST_ADD,
  COUNTRY_TOAST_REMOVE
} from '../constants';

export function getCountries(referrer) {
  return {
    type: GET_COUNTRIES,
    payload: { referrer }
  };
}

export function getCountriesInformation(countries) {
  return {
    type: GET_COUNTRIES_INFORMATION,
    payload: { countries }
  };
}

export function setCountries(countries) {
  return {
    type: SET_COUNTRIES,
    payload: { countries }
  };
}

export function setCountriesInformation(countriesInformation) {
  return {
    type: SET_COUNTRIES_INFORMATION,
    payload: { countriesInformation }
  };
}

export function updateSelectedCountry(country) {
  return {
    type: UPDATE_SELECTED_COUNTRY,
    payload: { country }
  };
}

export function updateNodePool(countryId, nodepoolId) {
  return {
    type: UPDATE_NODE_POOL,
    payload: { nodepoolId, countryId }
  };
}

export function addMemberTocountry(countryId, memberEmail) {
  return {
    type: ADD_MEMBER_TO_COUNTRY,
    payload: { countryId, memberEmail }
  };
}

export function removeMemberFromcountry(countryId, memberEmail) {
  return {
    type: REMOVE_MEMBER_FROM_COUNTRY,
    payload: { countryId, memberEmail }
  };
}

export function addAdminTocountry(countryId, adminEmail) {
  return {
    type: ADD_ADMIN_TO_COUNTRY,
    payload: { countryId, adminEmail }
  };
}

export function removeAdminFromcountry(countryId, adminEmail) {
  return {
    type: REMOVE_ADMIN_FROM_COUNTRY,
    payload: { countryId, adminEmail }
  };
}

export function addCountryToast(name, type, title) {
  return {
    type: COUNTRY_TOAST_ADD,
    payload: { name, type, title }
  };
}

export function removeCountryToast(country) {
  return {
    type: COUNTRY_TOAST_REMOVE,
    payload: { country }
  };
}
