import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import backendRequests from '../../api/backendRequests';
import { redirectAuth } from '../../utils/stateOauth';
import { setJobStatistics } from '../actions/statisticsActions';
import { updateAuthenticated } from '../actions/userActions';
import { initPermissions } from '../../utils/init-permissions';

export function* getJobStatistics(action) {
  try {
    const response = yield call(backendRequests.fetchJobStatistics, action.payload.startDate, action.payload.endDate);
    yield put(setJobStatistics(response));
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth('/');
      yield put(push(redirect));
    }
  }
}
