const basicRoutes = [
  {
    exact: true,
    component: 'JobsPage',
    url: '/jobs'
  },
  {
    component: 'JobItemPage',
    url: '/jobs/:jobid'
  },
  {
    exact: true,
    component: 'OrderOrchestrationPage',
    url: '/order-orchestration'
  },
  {
    exact: true,
    component: 'ProjectsPage',
    url: '/projects'
  },
  {
    exact: true,
    component: 'ScenariosPage',
    url: '/scenarios'
  },
  {
    exact: true,
    component: 'CountriesPage',
    url: '/countries'
  }
];

const notRegisteredRoutes = [
  {
    exact: true,
    component: 'NotRegisteredPage',
    url: '/jobs'
  },
  {
    component: 'NotRegisteredPage',
    url: '/jobs/:jobid'
  },
  {
    exact: true,
    component: 'NotRegisteredPage',
    url: '/order-orchestration'
  },
  {
    exact: true,
    component: 'NotRegisteredPage',
    url: '/projects'
  },
  {
    exact: true,
    component: 'NotRegisteredPage',
    url: '/scenarios'
  },
  {
    exact: true,
    component: 'NotRegisteredPage',
    url: '/countries'
  }
];

export default {
  cff_simulations_admin: {
    routes: [
      ...basicRoutes,
      {
        component: 'NewProjectPage',
        url: '/projects/create'
      },
      {
        component: 'StatisticsPage',
        url: '/statistics'
      }
    ]
  },
  cff_simulations_user: {
    routes: [
      ...basicRoutes,
      {
        component: 'NewProjectPage',
        url: '/projects/create'
      }
    ]
  },
  notRegistered: {
    routes: [...notRegisteredRoutes]
  }
};
