import { AUTHENTICATED_STARTED, AUTHENTICATED_SUCCESSFUL, LOGOUT, UPDATE_AUTHENTICATED } from '../constants';

const initialState = {
  authenticated: false,
  permissions: {}
};

export function userReducer(state = initialState, action) {
  switch (action.type) {
    case AUTHENTICATED_STARTED:
      return { ...state };
    case AUTHENTICATED_SUCCESSFUL:
      return { ...state, authenticated: true };
    case LOGOUT:
      return { ...state, authenticated: false };
    case UPDATE_AUTHENTICATED:
      return {
        ...state,
        authenticated: action.payload.authenticated,
        permissions: action.payload.permissions
      };
    default:
      return state;
  }
}
