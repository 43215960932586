import {
  SET_INPUT_FILES,
  SET_OUTPUT_FILES,
  GET_INPUT_FILES,
  GET_OUTPUT_FILES,
  UPLOAD_FILE_START,
  FILE_TOAST_ADD,
  FILE_TOAST_REMOVE
} from '../constants';

const initialState = {
  files: {
    input: [],
    output: []
  },
  loadingInputFiles: false,
  loadingOutputFiles: false,
  uploading: false,
  fileToasts: []
};

export function fileReducer(state = initialState, action) {
  switch (action.type) {
    case GET_INPUT_FILES:
      return { ...state, loadingInputFiles: true };
    case GET_OUTPUT_FILES:
      return { ...state, loadingOutputFiles: true };
    case SET_INPUT_FILES:
      return {
        ...state,
        files: { ...state.files, input: action.payload.files },
        loadingInputFiles: false
      };
    case SET_OUTPUT_FILES:
      return {
        ...state,
        files: { ...state.files, output: action.payload.files },
        loadingOutputFiles: false
      };
    case UPLOAD_FILE_START:
      return { ...state, uploading: true };
    case FILE_TOAST_ADD:
      return {
        ...state,
        fileToasts: [...state.fileToasts, action.payload]
      };
    case FILE_TOAST_REMOVE:
      return {
        ...state,
        fileToasts: state.fileToasts.filter(s => s.name !== action.payload.file),
        uploading: false
      };
    default:
      return state;
  }
}
