import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import rolesConfig from '../utils/rolesConfig';
import * as Routes from './routesConfig';
import NotFound from '../components/containers/NotFound';

const PrivateRoutes = ({ match }) => {
  const role = localStorage.getItem('roles')
    ? localStorage.getItem('roles')
    : 'notRegistered';

  let allowedRoutes = rolesConfig.notRegistered.routes

  if (role.includes('cff_simulations_user')) {
    allowedRoutes = rolesConfig.cff_simulations_user.routes
  } 
  if (role.includes('cff_simulations_admin')) {
    allowedRoutes = rolesConfig.cff_simulations_admin.routes
  }

  return (
    <div>
      <Switch>
        {allowedRoutes.map(({ component, url, exact }) => (
          <Route
            exact={exact}
            key={`${match.path}${url}`}
            path={`${match.path}${url}`}
            component={Routes[component]}
          />
        ))}
        <Route path={`${match.path}404`} component={NotFound} />
        <Redirect to="/404" />
      </Switch>
    </div>
  );
};

PrivateRoutes.propTypes = {
  match: PropTypes.object.isRequired
};

export default PrivateRoutes;
