import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Redirect } from 'react-router-dom';
import { encodeCurrentState } from '../../../utils/stateOauth';
import { startLogin } from '../../../store/actions/userActions';
import './Authenticate.scss';

const Authenticate = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { authenticated } = useSelector(state => state.user);
  const { selectedProject } = useSelector(state => state.projects);
  const { selectedScenario } = useSelector(state => state.scenarios);
  const referrer = location.state ? location.state.referrer : '/'
  const state = {
    referrer,
    selectedProject,
    selectedScenario
  };
  const encodedState = encodeCurrentState(state)

  useEffect(() => {
    dispatch(startLogin(encodedState, window.location.origin));
  },[dispatch, encodedState])


  if(authenticated){
    return <Redirect to="/" />
  }

  return (
    <div className="ikea-container" />
  );
}

export default Authenticate;
