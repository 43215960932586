import { call, put, all, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import backendRequests from '../../api/backendRequests';
import { redirectAuth } from '../../utils/stateOauth';
import {
  setCountries,
  getCountriesInformation as getCountriesInformationAction,
  setCountriesInformation,
  addCountryToast,
  removeCountryToast
} from '../actions/countryActions';
import { updateAuthenticated } from '../actions/userActions';
import { initPermissions } from '../../utils/init-permissions';

export function* getCountries(action) {
  try {
    const countries = yield call(backendRequests.fetchCountries);
    yield put(setCountries(countries.items));
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth(`/app/${action.payload.referrer}`);
      yield put(push(redirect));
    }
  }
}

export function* getCountriesInformation(action) {
  try {
    const countriesInformation = yield all(
      action.payload.countries.map(country => call(backendRequests.fetchCountryInformation, country.id))
    );
    yield put(setCountriesInformation(countriesInformation));
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth('/app/country-management');
      yield put(push(redirect));
    }
  }
}

export function* addMemberToCountry(action) {
  try {
    yield call(backendRequests.addMemberToCountry, action.payload.countryId, action.payload.memberEmail);
    yield put(addCountryToast(action.payload.memberEmail, 'confirmation', 'New member added!'));
    try {
      const countries = yield call(backendRequests.fetchCountries);
      yield put(setCountries(countries.items));
      yield delay(5000);
      yield put(removeCountryToast(action.payload.memberEmail));
    } catch (error) {
      if (error.response.status === 403 || error.response.status === 401) {
        yield put(updateAuthenticated(false, initPermissions(null)));
        const redirect = redirectAuth('/app/country-management');
        yield put(push(redirect));
      }
    }
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth('/app/country-management');
      yield put(push(redirect));
    } else if (error.response.status === 400) {
      yield put(addCountryToast(error.response.message, 'info-loud', 'New member failed!'));
      yield delay(5000);
      yield put(removeCountryToast(error.response.message));
    }
  }
}

export function* removeMemberFromCountry(action) {
  try {
    yield call(backendRequests.removeMemberFromCountry, action.payload.countryId, action.payload.memberEmail);
    yield put(addCountryToast(action.payload.memberEmail, 'confirmation', 'Member removed!'));
    const countries = yield call(backendRequests.fetchCountries);
    yield put(setCountries(countries.items));
    yield delay(5000);
    yield put(removeCountryToast(action.payload.memberEmail));
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth('/app/country-management');
      yield put(push(redirect));
    } else if (error.response.status === 400) {
      yield put(addCountryToast(error.response.message, 'info-loud', 'Remove member failed!'));
      yield delay(5000);
      yield put(removeCountryToast(error.response.message));
    }
  }
}

export function* addAdminToCountry(action) {
  try {
    yield call(backendRequests.addAdminToCountry, action.payload.countryId, action.payload.adminEmail);
    yield put(addCountryToast(action.payload.adminEmail, 'confirmation', 'New admin added!'));
    try {
      const countries = yield call(backendRequests.fetchCountries);
      yield put(setCountries(countries.items));
      yield delay(5000);
      yield put(removeCountryToast(action.payload.adminEmail));
    } catch (error) {
      if (error.response.status === 403 || error.response.status === 401) {
        yield put(updateAuthenticated(false, initPermissions(null)));
        const redirect = redirectAuth('/app/country-management');
        yield put(push(redirect));
      }
    }
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth('/app/country-management');
      yield put(push(redirect));
    } else if (error.response.status === 400) {
      yield put(addCountryToast(error.response.message, 'info-loud', 'New admin failed!'));
      yield delay(5000);
      yield put(removeCountryToast(error.response.message));
    }
  }
}

export function* removeAdminFromCountry(action) {
  try {
    yield call(backendRequests.removeAdminFromCountry, action.payload.countryId, action.payload.adminEmail);
    yield put(addCountryToast(action.payload.adminEmail, 'confirmation', 'Admin removed!'));
    const countries = yield call(backendRequests.fetchCountries);
    yield put(setCountries(countries.items));
    yield delay(5000);
    yield put(removeCountryToast(action.payload.adminEmail));
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth('/app/country-management');
      yield put(push(redirect));
    } else if (error.response.status === 400) {
      yield put(addCountryToast(error.response.message, 'info-loud', 'Remove admin failed!'));
      yield delay(5000);
      yield put(removeCountryToast(error.response.message));
    }
  }
}

export function* updateNodePool(action) {
  try {
    yield call(backendRequests.updateNodePool, action.payload.countryId, action.payload.nodepoolId);
    const countries = yield call(backendRequests.fetchCountries);
    yield put(getCountriesInformationAction(countries));
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth('/app/country-management');
      yield put(push(redirect));
    }
  }
}
