import { takeEvery } from 'redux-saga/effects';
import {
  GET_JOBS,
  GET_MORE_JOBS,
  GET_JOB,
  GET_PROJECTS,
  NEW_PROJECT,
  DELETE_PROJECT,
  UPDATE_PROJECT_SIMULATION,
  GET_SCENARIOS,
  GET_INPUT_FILES,
  GET_OUTPUT_FILES,
  UPLOAD_FILE_START,
  START_JOB,
  STOP_JOB,
  DELETE_JOB,
  AUTHENTICATED_STARTED,
  AUTHENTICATED_SUCCESSFUL,
  LOGOUT,
  NEW_SCENARIO,
  DELETE_SCENARIO,
  GET_SIMULATIONS,
  GET_SIMULATION_INFORMATION,
  GET_NODEPOOLS,
  GET_NODEPOOL,
  GET_JOB_EVENTS,
  GET_JOB_INPUT_FILES,
  GET_JOB_OUTPUT_FILES,
  GET_SUPPORT_INFORMATION,
  GET_JOB_STATISTICS,
  GET_COUNTRIES,
  GET_COUNTRIES_INFORMATION,
  UPDATE_NODE_POOL,
  ADD_MEMBER_TO_COUNTRY,
  REMOVE_MEMBER_FROM_COUNTRY,
  ADD_ADMIN_TO_COUNTRY,
  REMOVE_ADMIN_FROM_COUNTRY
} from '../constants';
import { startLogin, successfulLogin, logout } from './userSagas';
import { getSimulations, getSimulationInformation } from './simulationSagas';
import { getInputFiles, getOutputFiles, uploadFile } from './fileSagas';
import {
  getCountries,
  getCountriesInformation,
  updateNodePool,
  addMemberToCountry,
  removeMemberFromCountry,
  addAdminToCountry,
  removeAdminFromCountry
} from './countrySagas';
import { getProjects, addNewProject, removeProject, updateProjectSimulation } from './projectSagas';
import { getScenarios, addNewScenario, deleteScenario } from './scenarioSagas';
import {
  getJobs,
  getMoreJobs,
  getJob,
  startSimulationJob,
  stopSimulationJob,
  deleteSimulationJob,
  getJobEvents,
  getJobInputFiles,
  getJobOutputFiles
} from './jobSagas';
import { getNodePools, getNodePoolInformation } from './nodePoolSagas';
import { getSupportInformation } from './supportSagas';
import { getJobStatistics } from './statisticsSaga';

function* rootSaga() {
  yield takeEvery(GET_JOBS, getJobs);
  yield takeEvery(GET_MORE_JOBS, getMoreJobs);
  yield takeEvery(GET_JOB, getJob);
  yield takeEvery(START_JOB, startSimulationJob);
  yield takeEvery(STOP_JOB, stopSimulationJob);
  yield takeEvery(DELETE_JOB, deleteSimulationJob);
  yield takeEvery(GET_JOB_EVENTS, getJobEvents);
  yield takeEvery(GET_JOB_INPUT_FILES, getJobInputFiles);
  yield takeEvery(GET_JOB_OUTPUT_FILES, getJobOutputFiles);
  yield takeEvery(NEW_PROJECT, addNewProject);
  yield takeEvery(DELETE_PROJECT, removeProject);
  yield takeEvery(GET_PROJECTS, getProjects);
  yield takeEvery(UPDATE_PROJECT_SIMULATION, updateProjectSimulation);
  yield takeEvery(GET_SCENARIOS, getScenarios);
  yield takeEvery(NEW_SCENARIO, addNewScenario);
  yield takeEvery(DELETE_SCENARIO, deleteScenario);
  yield takeEvery(GET_INPUT_FILES, getInputFiles);
  yield takeEvery(GET_OUTPUT_FILES, getOutputFiles);
  yield takeEvery(UPLOAD_FILE_START, uploadFile);
  yield takeEvery(AUTHENTICATED_STARTED, startLogin);
  yield takeEvery(AUTHENTICATED_SUCCESSFUL, successfulLogin);
  yield takeEvery(LOGOUT, logout);
  yield takeEvery(GET_SIMULATIONS, getSimulations);
  yield takeEvery(GET_SIMULATION_INFORMATION, getSimulationInformation);
  yield takeEvery(GET_NODEPOOLS, getNodePools);
  yield takeEvery(GET_NODEPOOL, getNodePoolInformation);
  yield takeEvery(GET_SUPPORT_INFORMATION, getSupportInformation);
  yield takeEvery(GET_JOB_STATISTICS, getJobStatistics);
  yield takeEvery(GET_COUNTRIES, getCountries);
  yield takeEvery(GET_COUNTRIES_INFORMATION, getCountriesInformation);
  yield takeEvery(UPDATE_NODE_POOL, updateNodePool);
  yield takeEvery(ADD_MEMBER_TO_COUNTRY, addMemberToCountry);
  yield takeEvery(REMOVE_MEMBER_FROM_COUNTRY, removeMemberFromCountry);
  yield takeEvery(ADD_ADMIN_TO_COUNTRY, addAdminToCountry);
  yield takeEvery(REMOVE_ADMIN_FROM_COUNTRY, removeAdminFromCountry);
}

export default rootSaga;
