import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import backendRequests from '../../api/backendRequests';
import { redirectAuth } from '../../utils/stateOauth';
import { initPermissions } from '../../utils/init-permissions';
import { setNodePools, setNodePoolInformation } from '../actions/nodePoolActions';
import { updateAuthenticated } from '../actions/userActions';

export function* getNodePools() {
  try {
    const nodePools = yield call(backendRequests.fetchNodePools);
    yield put(setNodePools(nodePools.items));
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth('/app/countries');
      yield put(push(redirect));
    }
  }
}

export function* getNodePoolInformation(action) {
  try {
    const nodePoolInformation = yield call(backendRequests.fetchNodePoolInformation, action.payload.nodePoolId);
    yield put(setNodePoolInformation(action.payload.countryId, nodePoolInformation));
  } catch (error) {
    if (error.response.status === 403 || error.response.status === 401) {
      yield put(updateAuthenticated(false, initPermissions(null)));
      const redirect = redirectAuth('/app/countries');
      yield put(push(redirect));
    }
  }
}
