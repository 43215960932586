import { request, download, upload, post, put, remove, getQueryString } from './requestHelpers';
import { simulationBackend } from './globals';

const backendRequests = {
  // Simulations
  fetchSimulations() {
    return request(simulationBackend.simulations);
  },

  // Statistics
  fetchJobStatistics(startDate, endDate) {
    return request(`${simulationBackend.statistics}/jobs?startDate=${startDate}&endDate=${endDate}`);
  },

  // Node pools
  fetchNodePools() {
    return request(simulationBackend.nodePools);
  },

  fetchNodePoolInformation(nodePoolId) {
    return request(`${simulationBackend.nodePools}/${nodePoolId}`);
  },

  // Support information
  fetchSupportInformation() {
    return request(simulationBackend.support);
  },

  // Simulations
  fetchSimulationInformation(simulationId) {
    return request(`${simulationBackend.simulations}/${simulationId}`);
  },

  // Jobs
  fetchJobs() {
    return request(`${simulationBackend.jobs}`);
  },

  fetchJobsWithCursor(cursor, searchInput) {
    return request(`${simulationBackend.jobs}?cursor=${cursor}${getQueryString(searchInput, true)}`);
  },

  fetchJobsWithFilter(searchInput) {
    return request(`${simulationBackend.jobs}${getQueryString(searchInput, false)}`);
  },

  fetchJob(jobId) {
    return request(`${simulationBackend.jobs}/${jobId}`);
  },

  fetchJobEvents(jobId) {
    return request(`${simulationBackend.jobs}/${jobId}/events`);
  },

  fetchJobFiles(jobId, type) {
    return request(`${simulationBackend.jobs}/${jobId}/${type}/files`);
  },

  downloadJobFile(jobId, type, file) {
    return download(`${simulationBackend.jobs}/${jobId}/${type}/files/${file}`, file);
  },

  stopJob(jobId) {
    return post(`${simulationBackend.jobs}/${jobId}/stop`);
  },

  deleteJob(jobId) {
    return remove(`${simulationBackend.jobs}/${jobId}`);
  },

  // Projects
  fetchProjects(countryId) {
    return request(`${simulationBackend.countries}/${countryId}/projects`);
  },

  fetchProjectInformation(countryId, projectId) {
    return request(`${simulationBackend.countries}/${countryId}/projects/${projectId}`);
  },

  createProject(countryId, project) {
    return post(`${simulationBackend.countries}/${countryId}/projects`, project);
  },

  deleteProject(countryId, projectId) {
    return remove(`${simulationBackend.countries}/${countryId}/projects/${projectId}`);
  },

  startJob(countryId, projectId, scenarioId) {
    return post(`${simulationBackend.countries}/${countryId}/projects/${projectId}/scenarios/${scenarioId}/run`);
  },

  updateProjectSimulation(countryId, projectId, simulationId) {
    return put(`${simulationBackend.countries}/${countryId}/projects/${projectId}/simulation`, {
      id: simulationId
    });
  },

  // Country management
  fetchCountries() {
    return request(simulationBackend.countries);
  },

  fetchCountryInformation(countryId) {
    return request(`${simulationBackend.countries}/${countryId}`);
  },

  updateNodePool(countryId, nodePoolId) {
    return put(`${simulationBackend.countries}/${countryId}/node-pool`, {
      id: nodePoolId
    });
  },

  addMemberToCountry(countryId, memberEmail) {
    return post(`${simulationBackend.countries}/${countryId}/members`, {
      email: memberEmail
    });
  },

  removeMemberFromCountry(countrId, memberEmail) {
    return remove(`${simulationBackend.countries}/${countrId}/members/${memberEmail}`);
  },

  addAdminToCountry(countryId, adminEmail) {
    return post(`${simulationBackend.countries}/${countryId}/admins`, {
      email: adminEmail
    });
  },

  removeAdminFromCountry(countryId, adminEmail) {
    return remove(`${simulationBackend.countries}/${countryId}/admins/${adminEmail}`);
  },

  // Scenarios
  fetchScenarios(countryId, projectId) {
    return request(`${simulationBackend.countries}/${countryId}/projects/${projectId}/scenarios`);
  },

  addScenario(countryId, projectId, scenario) {
    return post(`${simulationBackend.countries}/${countryId}/projects/${projectId}/scenarios`, {
      name: scenario.name,
      description: scenario.description
    });
  },

  deleteScenario(countryId, projectId, scenarioId) {
    return remove(`${simulationBackend.countries}/${countryId}/projects/${projectId}/scenarios/${scenarioId}`);
  },

  // Files
  fetchFiles(countryId, projectId, scenarioId, type) {
    return request(
      `${simulationBackend.countries}/${countryId}/projects/${projectId}/scenarios/${scenarioId}/${type}/files`
    );
  },

  donwloadFile(countryId, projectId, scenarioId, file, type) {
    return download(
      `${simulationBackend.countries}/${countryId}/projects/${projectId}/scenarios/${scenarioId}/${type}/files/${file}`,
      file
    );
  },

  uploadFile(countryId, projectId, scenarioId, file) {
    return upload(
      `${simulationBackend.countries}/${countryId}/projects/${projectId}/scenarios/${scenarioId}/input/files/${file.name}`,
      file
    );
  },

  // Authentication
  authenticate(state, nonce, redirect) {
    window.location = `${simulationBackend.authentication}?state=${state}&nonce=${nonce}&redirect-uri=${redirect}`;
    return window.location;
  }
};

export default backendRequests;
