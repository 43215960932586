import {
  GET_JOBS,
  GET_MORE_JOBS,
  SET_JOBS,
  GET_JOB,
  SET_CURSOR,
  UPDATE_SELECTED_JOB,
  START_JOB,
  STOP_JOB,
  DELETE_JOB,
  SET_SEARCH_INPUT,
  GET_JOB_EVENTS,
  SET_JOB_EVENTS,
  JOB_TOAST_ADD,
  JOB_TOAST_REMOVE,
  GET_JOB_INPUT_FILES,
  GET_JOB_OUTPUT_FILES,
  SET_JOB_INPUT_FILES,
  SET_JOB_OUTPUT_FILES
} from '../constants';

export function getJobs(searchParam) {
  return {
    type: GET_JOBS,
    payload: { searchParam }
  };
}

export function getMoreJobs() {
  return {
    type: GET_MORE_JOBS
  };
}

export function setJobs(jobs) {
  return {
    type: SET_JOBS,
    payload: { jobs }
  };
}

export function setCursor(cursor) {
  return {
    type: SET_CURSOR,
    payload: { cursor }
  };
}

export function getJob(jobId, offset) {
  return {
    type: GET_JOB,
    payload: { jobId, offset }
  };
}

export function updateSelectedJob(job) {
  return {
    type: UPDATE_SELECTED_JOB,
    payload: { job }
  };
}

export function startJob() {
  return {
    type: START_JOB
  };
}

export function addJobToast(name, type, title, actionType) {
  return {
    type: JOB_TOAST_ADD,
    payload: { name, type, title, actionType }
  };
}

export function removeJobToast(name) {
  return {
    type: JOB_TOAST_REMOVE,
    payload: { name }
  };
}

export function setSearchInput(searchInput) {
  return {
    type: SET_SEARCH_INPUT,
    payload: { searchInput }
  };
}

export function stopJob(jobId) {
  return {
    type: STOP_JOB,
    payload: { jobId }
  };
}

export function deleteJob(jobId) {
  return {
    type: DELETE_JOB,
    payload: { jobId }
  };
}

export function getJobEvents(jobId) {
  return {
    type: GET_JOB_EVENTS,
    payload: { jobId }
  };
}

export function setJobEvents(events) {
  return {
    type: SET_JOB_EVENTS,
    payload: { events }
  };
}
export function getJobInputFiles(jobId) {
  return {
    type: GET_JOB_INPUT_FILES,
    payload: { jobId }
  };
}

export function getJobOutputFiles(jobId) {
  return {
    type: GET_JOB_OUTPUT_FILES,
    payload: { jobId }
  };
}

export function setJobInputFiles(files) {
  return {
    type: SET_JOB_INPUT_FILES,
    payload: { files }
  };
}

export function setJobOutputFiles(files) {
  return {
    type: SET_JOB_OUTPUT_FILES,
    payload: { files }
  };
}
