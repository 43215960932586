import {
  GET_INPUT_FILES,
  GET_OUTPUT_FILES,
  SET_INPUT_FILES,
  UPLOAD_FILE_START,
  SET_OUTPUT_FILES,
  FILE_TOAST_ADD,
  FILE_TOAST_REMOVE
} from '../constants';

export function getInputFiles(countryId, projectId, scenarioId) {
  return {
    type: GET_INPUT_FILES,
    payload: { countryId, projectId, scenarioId }
  };
}

export function getOutputFiles(countryId, projectId, scenarioId) {
  return {
    type: GET_OUTPUT_FILES,
    payload: { countryId, projectId, scenarioId }
  };
}

export function setInputFiles(files) {
  return {
    type: SET_INPUT_FILES,
    payload: { files }
  };
}

export function setOutputFiles(files) {
  return {
    type: SET_OUTPUT_FILES,
    payload: { files }
  };
}

export function startUploadFile(file) {
  return {
    type: UPLOAD_FILE_START,
    payload: { file }
  };
}

export function addFileToast(name, type, title) {
  return {
    type: FILE_TOAST_ADD,
    payload: { name, type, title }
  };
}

export function removeFileToast(file) {
  return {
    type: FILE_TOAST_REMOVE,
    payload: { file }
  };
}
