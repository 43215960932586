import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { decodeResponse, decodeJWT } from '../../../../utils/stateOauth';
import { successfulAuthenticated } from '../../../../store/actions/userActions';

const AuthenticateSuccess = () => {
  const dispatch = useDispatch();
  const decodedResponse = decodeResponse(window.location.hash)
  const jwtBody = decodeJWT(decodedResponse.id_token);
  useEffect(() => {
    dispatch(
      successfulAuthenticated(
        JSON.parse(decodedResponse.state),
        decodedResponse.id_token,
        jwtBody.nonce,
        jwtBody.upn,
        jwtBody.roles,
      )
    );
  },[dispatch, decodedResponse, jwtBody])

  return (
    <div />
  )
};

export default AuthenticateSuccess;
